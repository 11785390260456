<template>
  <v-form @submit.prevent="login()" class="login-form">
    <p class="input-label">Email <span>*</span></p>
    <v-text-field
      name="email"
      v-model="user.email"
      type="email"
      autocomplete="email"
      prepend-inner-icon="mail"
      outlined
    ></v-text-field>
    <p class="input-label">Password <span>*</span></p>
    <v-text-field
      name="password"
      v-model="user.password"
      type="password"
      prepend-inner-icon="lock"
      autocomplete="current-password"
      outlined
    ></v-text-field>
    <div class="btn-container">
      <router-link to="forgot-password" class="forgot-password">
        <p>Forgot password</p>
      </router-link>
      <v-btn class="login-btn" type="submit" color="primary">
        Login
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { Login } from "@/api/auth/login"
import EventBus from "@/event-bus"
export default {
  data() {
    return {
      user: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    async login() {
      try {
        await Login(this.user)

        EventBus.$emit("toast", {
          text: "Logged in successfully",
          type: "success"
        })

        this.$router.push({ name: "requests" })
      } catch (e) {
        EventBus.$emit("toast", {
          text: "Error Logging in",
          type: "error"
        })
        throw e
      }
    }
  }
}
</script>

<style scoped>
.login-form {
  font-size: 20px;
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.login-btn {
  font-size: 20px;
  letter-spacing: normal;
}
.forgot-password {
  color: var(--v-grey-base);
  align-self: center;
}
.forgot-password p {
  margin: 0;
}
.input-label {
  letter-spacing: normal;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}

@media only screen and (max-width: 767px) {
  .btn-container {
    margin-top: 0;
  }
  .login-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .input-label {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .forgot-password p {
    font-size: 18px;
  }
  ::v-deep .v-btn__content {
    font-size: 18px;
  }
  .login-btn {
    width: 120px;
    height: 50px !important;
    padding: 10px 40px;
    border-radius: 10px;
  }
}
</style>
