import { CompanyAuthService } from "@/api/instance"
import { SITE } from "@/config"
import store from "@/store"
import { DateTime } from "luxon"
import * as Sentry from "@sentry/vue"

export const Login = async data => {
  data.userType = "COMPANY"
  data.site = SITE
  let {
    data: { token, self, last_seen }
  } = await CompanyAuthService.post("/login", data)

  last_seen = DateTime.fromISO(last_seen)
    .setZone("utc")
    .toISO()

  localStorage.setItem("token", token)
  localStorage.setItem("self", self)
  localStorage.setItem("last_seen", last_seen)
  store.commit("set_token", token)
  store.commit("set_self", self)
  store.commit("set_last_seen", last_seen)
  Sentry.setUser({
    token: token,
    id: self
  })
}
