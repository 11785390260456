<template>
  <v-form ref="form" @submit.prevent="register()" class="register-form">
    <p class="input-label">Company Name <span>*</span></p>
    <v-text-field
      v-model="user.company.company_name"
      id="company_name"
      :rules="[rules.required]"
      outlined
      required
    ></v-text-field>
    <p class="input-label">Email <span>*</span></p>

    <v-text-field
      name="email"
      v-model="user.email"
      id="email"
      type="email"
      :rules="[rules.required, rules.email]"
      outlined
      required
    ></v-text-field>
    <p class="input-label">Password <span>*</span></p>

    <v-text-field
      name="password"
      id="password"
      v-model="user.password"
      type="password"
      :rules="[rules.required, rules.password]"
      outlined
      required
    ></v-text-field>
    <div class="split-input-container">
      <div>
        <p class="input-label">First Name <span>*</span></p>
        <v-text-field
          name="first_name"
          id="first-name"
          v-model="user.company.first_name"
          type="text"
          :rules="[rules.required]"
          outlined
          required
        ></v-text-field>
      </div>
      <div>
        <p class="input-label">Last Name <span>*</span></p>
        <v-text-field
          name="last_name"
          id="last-name"
          v-model="user.company.last_name"
          type="text"
          :rules="[rules.required]"
          outlined
          required
        ></v-text-field>
      </div>
    </div>

    <p class="input-label">Role Within Company</p>

    <v-text-field
      v-model="user.role"
      id="company-role"
      outlined
      type="text"
    ></v-text-field>
    <p class="input-label">Website</p>

    <v-text-field
      v-model="user.company.website"
      id="website"
      outlined
    ></v-text-field>

    <p class="input-label">Telephone Number <span>*</span></p>

    <v-text-field
      v-model="user.company.tel"
      id="telephone"
      :rules="[rules.required, rules.phone, rules.phoneLength]"
      outlined
      required
    ></v-text-field>

    <AddressPicker
      v-if="showAddressPicker"
      :base-url="baseURL"
      v-model="user.address"
      :rules="[rules.required]"
    >
      <template v-slot:map>
        <div style="height: 50%; width: 50%">
          <StaticMap
            :maps-key="mapsKey"
            :lat="user.address.lat"
            :lng="user.address.lng"
            :width="900"
            :height="900"
            :zoom="17"
          />
        </div>
      </template>
    </AddressPicker>
    <template v-else>
      <p class="input-label">
        Address
        <span>*</span>
      </p>
      <v-textarea
        name="address"
        id="address"
        v-model="user.address.formatted_address"
        outlined
        type="text"
        :rules="[rules.required]"
        @keypress="address_entered"
      ></v-textarea>
    </template>
    <p class="input-label">
      Type of Care Provided
      <span>*</span>
    </p>
    <p class="input-hint">
      Please select which types of care your company can provide
    </p>
    <v-select
      outlined
      id="care-type"
      v-model="user.company.care_type_provided"
      :items="careTypes"
      :menu-props="{ maxHeight: '400' }"
      label="Select Care Type"
      multiple
      chips
      :rules="[rules.requiredSelected]"
    >
      <template #selection="{ item }">
        <CareType :care-type="item" id="item" /> </template
    ></v-select>

    <p class="input-label">
      Area you cover
      <span>*</span>
    </p>
    <p class="input-hint">
      For each type of care that you provide, please select the area you cover
      in miles. You will not receive requests for this type of care outside your
      coverage area. For example, if you select 2 for ‘hourly care’, you will
      only receive hourly care requests where the postcode of the person
      requesting care is within a 2 mile radius of your postcode (as stated on
      your profile). If you cover the entire country (for example for live-in
      care), please tick ‘All of England’.
    </p>
    <CoverageSelector
      v-model="user.company.coverage"
      :care-types="providedCareTypes"
      :lat="lat"
      :lng="lng"
    />

    <p class="input-label" style="margin-top: 20px">Bio <span>*</span></p>
    <v-textarea
      type="text"
      id="bio"
      v-model="user.company.bio"
      :rules="[rules.required]"
      outlined
      required
    ></v-textarea>

    <p class="input-label">
      CQC Location URL
      <span>*</span>
    </p>
    <p class="input-hint">
      This is your cqc location link. Example:
      https://www.cqc.org.uk/location/1-23456789
    </p>
    <v-text-field
      outlined
      v-model="user.company.cqc_profile"
      :rules="[rules.required, rules.cqc]"
      id="cqc_location_url"
    ></v-text-field>
    <CQCProfile :link="this.user.company.cqc_profile" :base-url="baseURL" />

    <v-checkbox v-model="user.terms" id="terms">
      <template v-slot:label>
        <p class="consent-label">
          Please tick here to confirm you agree to our
          <a @click.stop="viewTermsAndConditions = true"
            >Terms and Conditions</a
          >
          and have read our
          <a @click.stop="viewCookieNotice = true">Cookie Notice</a> and our
          <a @click.stop="viewPrivacyPolicy = true">Privacy Notice</a> that
          contains information about how we use your personal data and your
          rights.
        </p>
      </template>
    </v-checkbox>

    <v-btn class="register-btn" color="primary" type="submit">Register</v-btn>

    <v-dialog v-model="viewTermsAndConditions" max-width="1024px">
      <v-card>
        <v-card-text class="pa-5">
          <object type="application/pdf" :data="termsURL"></object>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lightGrey" @click.stop="viewTermsAndConditions = false"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" target="_blank" :href="termsURL"
            >View PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPrivacyPolicy" max-width="1024px">
      <v-card>
        <v-card-text class="pa-5">
          <object :data="privacyURL"></object>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lightGrey" @click.stop="viewPrivacyPolicy = false"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" target="_blank" :href="privacyURL"
            >View PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewCookieNotice" max-width="1024px">
      <v-card>
        <v-card-text class="pa-5">
          <object :data="cookieURL"></object>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lightGrey" @click.stop="viewCookieNotice = false"
            >Back</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" target="_blank" :href="cookieURL"
            >View PDF</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="registeredModalIsVisible" max-width="1024px">
      <v-card v-if="registeredSuccesful">
        <v-toolbar dark color="primary">
          <v-card-title>Thank you for registering</v-card-title>
        </v-toolbar>
        <v-card-text style="margin-top: 20px; padding-bottom: 12px">
          <p>
            An email will now be sent to you with an activation link. (Please
            note this may take a few minutes.)
          </p>
          <p>
            If you do not receive the email please check your spam/junk folder.
          </p>
          <p>
            If you experience any issues please contact our help desk using the
            icon in the bottom right of your screen.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn to="login" color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
// import { BASE_URL } from "@/config";
import EventBus from "@/event-bus"
// import axios from "axios";
import { Register } from "@/api/auth/register"
import { SearchCQC } from "@/api/cqc/search"
import { GeocodeAddress } from "@/api/profile"
import CareType from "@/components/careType.vue"
import CoverageSelector from "@/components/coverageSelector.vue"
import {
  SITE,
  BASE_URL,
  COMPANY_SERVICE_PORT,
  MAPS_KEY,
  SHOW_ADDRESS_PICKER
} from "@/config"
import CQCProfile from "component-library/src/components/CQCProfile/CQCProfile.vue"
import AddressPicker from "component-library/src/components/AddressPicker/AddressPicker.vue"
import StaticMap from "component-library/src/components/StaticMap/StaticMap.vue"
export default {
  components: {
    AddressPicker,
    CQCProfile,
    StaticMap,
    CareType,
    CoverageSelector
  },
  data() {
    return {
      lat: null,
      lng: null,
      loading: false,
      items: [],
      mapsKey: `${MAPS_KEY}`,
      baseURL: `${BASE_URL}${COMPANY_SERVICE_PORT}`,
      showAddressPicker: SHOW_ADDRESS_PICKER,
      search: null,
      select: null,
      viewTermsAndConditions: false,
      termsURL:
        "https://company-legal.carecompare.net/Terms and Conditions.pdf",
      viewPrivacyPolicy: false,
      privacyURL: "https://company-legal.carecompare.net/Privacy Policy.pdf",
      viewCookieNotice: false,
      cookieURL: "https://company-legal.carecompare.net/Cookie Notice.pdf",
      user: {
        email: "",
        password: "",
        company: {
          completeCoverage: null
        },
        userType: "COMPANY",
        terms: false,
        address: {
          lat: 0,
          lng: 0
        },
        addressTimeout: null
      },
      rules: {
        required: value => !!value || "Required",
        requiredSelected: value => value.length > 0 || "Required",
        requiredRadio: value => value !== null || "Required",
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          return pattern.test(value) || "Invalid email"
        },
        password: value => value.length >= 8 || "Password is too short",
        postcode: value => {
          const pattern = /(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})/g
          return pattern.test(value) || "Invalid postcode "
        },
        cqc: value => {
          const pattern = /https:\/\/www.cqc.org.uk\/location\/[A-Z0-9-]{1,14}$/
          return pattern.test(value) || "Invalid cqc location link"
        },
        phone: value => {
          const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
          return pattern.test(value) || "Invalid phone number"
        },
        phoneLength: value => value?.length >= 3 || "Phone number is too short"
      },
      registeredModalIsVisible: false,
      registeredSuccesful: false,
      registeredFail: false,
      registeredFailErrorMessage: "",
      careTypes: ["Hourly Care", "Live In Care", "Night Time Care"]
    }
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    }
  },
  computed: {
    providedCareTypes: {
      cache: false,
      get() {
        return this.user.company.care_type_provided
      }
    }
  },
  methods: {
    async querySelections(v) {
      clearTimeout(this._timerId)

      this._timerId = setTimeout(async () => {
        this.loading = true
        this.items = await SearchCQC(v)
        this.loading = false
      }, 500)
    },
    async register() {
      if (!this.$refs.form.validate()) {
        EventBus.$emit("toast", {
          text: "Please check for fields that need amending",
          type: "error"
        })
        return
      }
      if (this.user.terms === false) {
        EventBus.$emit("toast", {
          text: "Must accept Terms & Conditions and/or Privacy Policy",
          type: "error"
        })
        return
      }
      this.user.privacy = this.user.terms
      try {
        this.user.site = SITE
        await Register(this.user)
        EventBus.$emit("toast", { text: "Registered", type: "success" })
        this.registeredSuccesful = true
        this.registeredFail = false
        this.registeredFailErrorMessage = null
        this.toggleRegisteredModal()
      } catch (err) {
        this.registeredFailErrorMessage = err?.response?.data?.message
        this.registeredFail = true
        this.registeredSuccesful = false
        this.toggleRegisteredModal()
        throw err
      }
    },
    toggleRegisteredModal() {
      this.registeredModalIsVisible = !this.registeredModalIsVisible
    },
    onChange(image) {
      if (image) {
        this.imageData = this.$refs.pictureInput.file
      }
    },
    async address_entered() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        if (this.user?.address?.formatted_address?.length > 0) {
          const res = await GeocodeAddress(this.user.address.formatted_address)
          this.lat = res.lat
          this.lng = res.lng
        }
      }, 300)
    }
  }
}
</script>

<style scoped>
.consent-label {
  margin: 0;
}
a {
  text-decoration: underline;
}
::v-deep .input-label {
  letter-spacing: normal;
  font-size: 22px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}

.input-hint {
  color: var(--v-grey-lighten1);
}
.register-btn {
  float: right;
  font-size: 20px;
  letter-spacing: normal;
  margin-bottom: 20px;
}
.split-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.split-input-container > div {
  width: 49%;
}

.tc-box {
  margin-bottom: 32px;
}
object {
  width: 100%;
  min-height: 500px;
  max-height: 600px;
}
</style>
