<template>
  <div>
    <v-app-bar app fixed class="title-bar">
      <Logo />
      <v-spacer></v-spacer>
      <router-link
        v-if="$route.name === 'register'"
        to="login"
        class="nav-link"
      >
        <p>Login</p>
      </router-link>
      <router-link v-else to="register" class="nav-link">
        <p>Register</p>
      </router-link>
    </v-app-bar>

    <v-main style="height: 100%; padding-top: 110px">
      <div class="content-container">
        <v-row justif="center" class="fill-height">
          <v-col
            cols="12"
            sm="10"
            offset-sm="1"
            md="10"
            offset-md="1"
            lg="8"
            offset-lg="2"
            xl="8"
            offset-xl="2"
          >
            <v-card flat class="auth-card">
              <v-card-title class="title">
                <h3>{{ $route.name.split("-").join(" ") }}</h3>
              </v-card-title>
              <v-container class="content-container">
                <router-view></router-view>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </div>
</template>

<script>
import { SITE } from "@/config"
import Logo from "@/components/logo.vue"

export default {
  name: "Auth",
  components: {
    Logo
  },
  computed: {
    siteName() {
      return SITE.toLowerCase()
    }
  }
}
</script>

<style scoped>
.auth-card {
  border-radius: 10px;
}
.nav-link {
  color: var(--v-grey-base) !important;
  font-size: 20px;
}
.title {
  text-transform: capitalize;
  color: var(--v-grey-base);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 50px;
  margin-bottom: 60px;
}

.title > h3 {
  font-size: 32px;
  font-weight: 400;
}

.logo {
  padding: 5px;
  width: 310px;
  height: 100%;
  padding: 5px;
}
::v-deep .v-toolbar__content {
  justify-content: center;
  flex-wrap: wrap;
  max-width: 950px;
  margin: 0 auto;
  height: 96px !important;
}
::v-deep .v-app-bar {
  height: 96px !important;
  box-shadow: none !important;
  background-color: #fff !important;
}

.content-container {
  width: 95%;
  margin: 15px auto;
  max-width: 1200px;
}

.title-bar {
  margin-bottom: 30px;
  font-size: 22px;
}

::v-deep .v-list-item__action {
  margin-right: 8px !important;
}
.list-item {
  color: var(--v-grey-base) !important;
  padding: 8px 16px;
}
.active {
  color: var(--v-primary-base) !important;
}
.nav-bar-icon {
  display: none;
  padding: 0 32px;
}

@media only screen and (max-width: 360px) {
  .content-container {
    width: 100%;
    margin: 0;
  }
  .title-bar {
    margin-bottom: 30px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .app-bar-mobile {
    height: 100px !important;
  }
  .main-mobile {
    padding: 100px 0 0 !important;
  }
}
</style>
